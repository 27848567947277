import interpolate from 'interpolate'

import en from './translations/en/messages.json' with { type: 'json' }
import es from './translations/es_419/messages.json' with { type: 'json' }
import pt from './translations/pt_BR/messages.json' with { type: 'json' }
import de from './translations/de/messages.json' with { type: 'json' }

type Struct = Record<string, string> | Record<string, { one?: string; other?: string }>

export const supportedLanguages: Record<string, { translate: Struct }> = {
  en: {
    translate: en,
  },
  es: {
    translate: es,
  },
  pt: {
    translate: pt,
  },
  de: {
    translate: de,
  },
}

export type Language = keyof typeof supportedLanguages

const delimiter = '{{  }}'

function getTranslated(locale: Language, str: string) {
  return supportedLanguages?.[locale]?.translate?.[str] || str
}

function singular(locale: Language, str: string, scope?: Record<string, string>) {
  let translated = getTranslated(locale, str)
  if (typeof translated !== 'string' && translated.one) {
    translated = translated.one
  }
  return interpolate(translated, scope, { delimiter })
}

function plural(
  locale: Language,
  count: number,
  str: string,
  strPlural: string,
  scope?: Record<string, string>
) {
  const translated = getTranslated(locale, str)
  if (typeof translated === 'object') {
    const msgtranslated = count !== 1 ? translated.other || strPlural : translated.one || str
    return interpolate(msgtranslated, scope, { delimiter })
  }
  interpolate(translated, scope, { delimiter })
}

const translator = {
  singular,
  plural,
}

export default translator
