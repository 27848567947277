import useLanguage from '~/contexts/language/useLanguage'
import Translator from '~/utils/Translator'

export default function useTranslator() {
  const lang = useLanguage()
  return {
    // TODO: Implement languages
    singular: (msg: string, scope?: Record<string, string>) => {
      if (lang) {
        return Translator.singular(lang, msg, scope)
      }
      return msg
    },
  }
}
